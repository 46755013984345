.HomeCon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 50px);
  font-family: sans-serif;
  background-image: url("../Asset/Images/banner.png");
}
.Homeoverlay {
  background-color: rgba(0, 165, 79, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 50px);
  font-family: sans-serif;
}
.HomeWrapper {
  display: flex;
  width: 90%;
}

@media (max-width: 768px) {
  .HomeCon,
  .Homeoverlay {
    height: fit-content;
  }
  .HomeWrapper {
    flex-direction: column !important;
  }
  .ImgCon {
    padding-top: 10px;
  }
}
.TextCon {
  flex: 6;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  padding-right: 20px;
}
.TextCon .homefirst {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor);
}
.TextCon p span {
  color: rgba(255, 99, 71, 0.8);
}
.TextCon p {
  font-size: 20px;
}
.homeButton {
  background-color: var(--primaryBacgkround);
  color: var(--primaryColor);
  padding: 10px 18px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  width: fit-content;
}

.homeButton:hover {
  background-color: var(--primaryBacgkround);
  color: var(--primaryColor);
  transition: ease-in-out 0.5s;
  box-shadow: 1px 2px 2px 3px white;
}
.ImgCon {
  display: flex;
  flex: 6;
  height:80vh;
  width: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /* background-color: var(--primaryBacgkround); */
}
.ImgCon video{
  width: 100%;
  height:80vh;
  outline: none;
  -webkit-background-size:cover;
  background-size: cover;
  border-radius: 5px;
}
@media (max-width:800px) {
  .ImgCon video{
    height: auto;
  }
}
/* .ImgCon video */

/* ============================ HOME SECTION VISSION/ MISSION ================  */
.HomeSecCon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 0;
  background-color: var(--primaryBacgkround);
}
.HomeSecWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 90%;
}

.HomeSecWrapper .vission,
.mission {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

@media (max-width: 768px) {
  .vission {
    flex-direction: column;
  }
  .mission {
    flex-direction: column-reverse;
  }
}
.writeUp {
  flex: 6;
  padding-right: 30px;
}
.writeUp h3 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor);
  padding-bottom: 30px;
}
.writeUp p {
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 20px;
}
.ImageCon {
  flex: 6;
}
.ImageCon img{
  height: auto;
  width: 100%;
}

.missionWrites {
  text-align: start;
}
.ourmissionAll {
  height: 40vh;
  overflow-y: scroll;
}

.ourmissionAll::-webkit-scrollbar {
  width: 30px;
}

.ourmissionAll h2 {
  padding-bottom: 10px;
}
.ourmissionAll ol li {
  padding: 10px 0;
  font-size: 14px;
}
.ourmissionAll ol p {
  font-size: 16px;
  padding: 20px 0;
  font-weight: 700;
}

/* .pledge{
  text-align: center;

}
.pledge h3, .pledge h2 {
  font-size:20px;
  font-weight: 700;
  color: var(--primaryColor);
  padding-bottom: 10px;
} */

.Whatwedo {
  text-align: center;
  margin-top: 30px;
}
.Whatwedo h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor);
  padding-bottom: 30px;
}
.whatwedoWrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .whatwedoWrapper {
    flex-direction: column !important;
  }
  .whatwedoWrapper .imgCon img {
    width: 100% !important;
    padding-top: 20px;
  }
}
.whatwedoWrapper .TetCon {
  flex: 6;
  text-align: left;
}

.whatwedoWrapper .TetCon h3 {
  padding-bottom: 20px;
  font-size: 20px;
}
.whatwedoWrapper .TetCon p {
  line-height: 25px;
}
.whatwedoWrapper .TetCon button {
  background-color: white;
  color: rgb(0, 165, 78);
  font-weight: 700;
  margin-top: 20px;
}

.whatwedoWrapper .TetCon button:hover {
  background-color: var(--primaryBacgkround);
  color: var(--primaryColor);
  transition: ease-in-out 0.5s;
  box-shadow: 1px 2px 2px 3px white;
}
.whatwedoWrapper .imgCon {
  flex: 6;
  display: flex;
  justify-content:center;
  align-items: center;
  height: fit-content;
}
.whatwedoWrapper .imgCon img {
  /* width: 70%; */
}

.conceptEp h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primaryColor);
  padding-bottom: 30px;
}

.SDG h2{
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primaryColor);
  padding-bottom:10px;
}
.SDG p{
  font-size:1rem;
  font-weight:500;
}
.goalsImgCon{
  display: flex;
  justify-content: flex-start;
  align-items:center;
  gap: 30px;
  flex-wrap: wrap;
}
.goalsImgCon img{
  width: 150px;
  margin-top: 20px;
}