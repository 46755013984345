/* :: 12.0 Gallery Area CSS */
.medialCon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
}

.gallery-area {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: 30px 0;
  flex-wrap: wrap;
}
.gallery-area .single-gallery-area {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  width: 20%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .gallery-area .single-gallery-area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .gallery-area .single-gallery-area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .gallery-area .single-gallery-area {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    width: 50%;
  }
}
.gallery-area .single-gallery-area a {
  display: block;
  overflow: hidden;
  cursor: url(../Asset/Images/zoom.png), auto;
}
.gallery-area .single-gallery-area a img {
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  height: 150px;
}
.gallery-area .single-gallery-area:hover a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-filter: blur(5px);
  filter: blur(5px);
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #ffffff;
  right: 0;
  text-align: center;
  padding-right: 0;
  top: 40px;
  width: 36px;
  height: 36px;
  background-color: #c92f2f;
  line-height: 36px;
}

.mfp-bottom-bar {
  margin-top: 0;
  top: auto;
  bottom: 55px;
  left: 0;
  width: 100%;
  cursor: auto;
  background-color: transparent;
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.mfp-bottom-bar .mfp-title {
  line-height: normal;
  color: #ffffff;
  padding-right: 0;
  background-color: #c92f2f;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 700;
}
.mfp-bottom-bar .mfp-counter {
  color: #ffffff;
  position: relative;
  line-height: normal;
  background-color: #c92f2f;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 700;
}

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 165, 79, 0.5);
  overflow: hidden;
}
.cardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.card {
  width: 50%;
  background-color: white;
  padding: 20px;
  height: 90vh;
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .card {
    width: 90%;
  }
}
.card img {
  width: 50%;
}
.card-title {
  padding: 10px 0;
  font-size: 24px;
}
.card-text {
  line-height: 20px;
}
.card::-webkit-scrollbar{
    display: none;
}
.cancle {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  color: #666;
  font-size: 25px;
  cursor: pointer;
}
.cancle button {
  padding: 5px 18px;
  background-color: var(--primaryBacgkround);
  color: #666;
  font-size: 25px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 6px;
}
.videoCon{
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}
.videoWrapper_main{
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.videoWrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* gap:10px; */
}
.videoCon video{
  width:50%;
  border: none;
  outline: none;
}
.videoCon h2{
  text-align: center;
  font-size:1.5rem;
  font-weight: 700;
  color: var(--primaryColor);
}

@media (max-width:880px) {
  .videoCon video{
    width: 100%;
  }
}